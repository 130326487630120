.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/** Services **/
.services {
  background: url('../public/assets/images/services-overlay-min.webp');
  background-size: cover;
  background-attachment: fixed;
}

@media (max-width: 667px) {
  .services {
    background: url('../public/assets/images/services-overlay-min-mobile.jpg');
    background-attachment: initial;
    background-size: 100% auto;
  }

  .w3l-featureshny {
    background: url(../public/assets/images/view-our-team-mobile.jpg) no-repeat center;
  }
}

.services-title {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 2em;
  letter-spacing: 1px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.sub-title {
  font-size: 0.35em;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
}

p.main_p4 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  width: 80%;
  font-size: 1em;
  letter-spacing: 0.075em;
  line-height: 1.9em;
}

.check {
  width: 12px;
  margin-right: 3px;
  position: relative;
  bottom: 2px;
}

.service-label {
  font-weight: 300;
  color: #fff;
  margin-bottom: 4px;
  font-size: .75em;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

/** End Services **/

/** Team **/
.team h6,
.team p,
.team h3 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
.team p {
  font-size: .9em;
    color: #fff;
    line-height: 2em;
    letter-spacing: 1px;
}
/** End Team **/